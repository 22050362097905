import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { memo, useState } from "react";
import OutboundRequestConfig from "./OutboundRequestConfig";
import { Refresh } from "@mui/icons-material";

import { getCarrierResponse } from "services/CarrierAPI";
import { toast } from "react-toastify";

import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const OutboundConfig = ({ carrierData, setCarrierData }) => {
  const handleKeyValueChange = (newKeyValue) => {
    // Convert the keyValue to JSON and update the carrierData's request_body
    console.log("Carrier Request", newKeyValue);
    setCarrierData((prev) => ({
      ...prev,
      request_body: newKeyValue,
    }));

    console.log("Carrier Request", carrierData);
  };

  // Fetch response handler to log carrierData JSON
  const onFetchResponse = async () => {
    if (!carrierData?.send_url) {
      toast.error("API Send URL is missing!");
      return;
    }
    // Function to check if all required params have been defined and track missing or duplicate ones
    const validateParams = () => {
      const selectedParams = new Set();
      const keysSet = new Set();
      const duplicates = new Set();
      const keyValue = carrierData?.request_body;

      // Loop over sections (headers, params, body) and track params and keys
      ["headers", "params", "body"].forEach((section) => {
        keyValue[section].forEach((item) => {
          // Check if param exists and value is not empty
          if (item.param && item.value.trim() !== "") {
            selectedParams.add(item.param);
          }

          // Track duplicate keys
          if (item.key) {
            if (keysSet.has(item.key)) {
              duplicates.add(item.key);
            } else {
              keysSet.add(item.key);
            }
          }
        });
      });

      // Track the missing params by comparing to required options
      const missingParams = CARRIER_REQ_PARAMS_OPT.filter(
        (option) => !selectedParams.has(option.key)
      );

      return { missingParams, duplicates: [...duplicates] };
    };

    // Perform validation before making the request
    const { missingParams, duplicates } = validateParams();

    if (missingParams.length > 0) {
      const missingKeys = missingParams.map((param) => param.value).join(", ");
      toast.error(
        `Please define values for the following params: ${missingKeys}`
      );
      return;
    }

    if (duplicates.length > 0) {
      const duplicateKeys = duplicates.join(", ");
      toast.error(
        `Duplicate keys found: ${duplicateKeys}. Keys must be unique.`
      );
      return;
    }

    // Proceed with the API call if validation passes
    try {
      const response = await getCarrierResponse(carrierData);

      setCarrierData((prev) => ({
        ...prev,
        response_body:
          JSON.stringify(response?.data, null, 2) || response?.message,
      }));

      if (response.status !== 200) {
        toast.error("Carrier Response Not Valid");
        return;
      }

      toast.success("Carrier Response Is Valid");
    } catch (error) {
      toast.error("Error fetching the response. Please try again.");
    }
  };

  const onHttpMethodChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        http_method: e.target.value,
      };
    });
  };
  const onEncodingTypeChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        encoding_type: e.target.value,
      };
    });
  };
  const onContentTypeChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        content_type: e.target.value,
      };
    });
  };
  const onMaxLengthChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        max_length: e.target.value,
      };
    });
  };
  const onAuthChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        auth_val: e.target.value,
      };
    });
  };

  const onSendUrlChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        send_url: e.target.value,
      };
    });
  };

  const onRequestBodyChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        request_body: e.target.value,
      };
    });
  };

  const onExampleRequestBodyChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        example_request_body: e.target.value,
      };
    });
  };

  const onResponseBodyChange = (e) => {
    setCarrierData((prev) => {
      return {
        ...prev,
        response_body: e.target.value,
      };
    });
  };

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="center">
        <Typography variant="h5" m={2}>
          {"Outbound configurations"}
        </Typography>
      </MDBox>

      <MDBox
        margin={"8px 8px 12px 8px"}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <MDBox>
          <FormControl fullWidth>
            <InputLabel id="http-method">Http Methods</InputLabel>
            <Select
              labelId="http-method"
              id="http-method-select"
              value={carrierData.http_method}
              sx={{
                height: "42px",
                marginRight: "8px",
              }}
              label="HTTP Methods"
              onChange={onHttpMethodChange}
            >
              <MenuItem value={"POST"}>HTTP POST</MenuItem>
              <MenuItem value={"PUT"}>HTTP PUT</MenuItem>
              <MenuItem value={"GET"}>HTTP GET</MenuItem>
            </Select>
          </FormControl>
        </MDBox>
        <MDBox width="100%!important">
          <MDInput
            fullWidth
            onChange={onSendUrlChange}
            type="text"
            label="Send URL"
            value={carrierData.send_url}
          />
        </MDBox>
      </MDBox>

      <MDBox margin={"12px 0px 12px 0px"} width="100%">
        <OutboundRequestConfig onKeyValueChange={handleKeyValueChange} />
      </MDBox>

      <MDBox margin={"8px"}>
        <MDBox sx={{ position: "relative" }}>
          <MDInput
            multiline
            minRows={4}
            sx={{ marginRight: "8px" }}
            fullWidth
            type=""
            label="MT Response check"
            value={carrierData.response_body}
          />
          <Button
            sx={{ position: "absolute", bottom: 0, right: 0 }}
            startIcon={<Refresh />}
            onClick={onFetchResponse}
          >
            {"Fetch response"}
          </Button>
        </MDBox>
        <Typography margin={"8px"} fontSize={"12px"}>
          The content that must be in the response to consider the request
          successful
        </Typography>
      </MDBox>
    </MDBox>
  );
};
export default memo(OutboundConfig);
