import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import { SelectStyle } from "components/ImportChat/MappedColumns";
import { CARRIER_INS } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useCallback, useState, useEffect } from "react";

const AUTH_OPTION = {
  NO_AUTH: { name: "No Auth", key: "no_auth" },
  BASIC_AUTH: { name: "Basic Auth", key: "basic_auth" },
  BEARER_TOKEN: { name: "Bearer Token", key: "bearer_token" },
};

const AuthParams = ({ onAuthChange, authValue }) => {
  const [authOption, setAuthOption] = useState(
    authValue
      ? { name: authValue.name, key: authValue.key }
      : AUTH_OPTION.NO_AUTH
  );
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [bearerToken, setBearerToken] = useState("");

  const onChangeAuthOptionChange = (e) => {
    const selectedKey = e.target.value;
    const selectedOption = Object.values(AUTH_OPTION).find(
      (opt) => opt.key === selectedKey
    );
    setAuthOption(selectedOption);

    // Reset data fields when auth option changes
    setUserName("");
    setPassword("");
    setBearerToken("");
  };

  // Handle input changes
  const onUserNameChange = (e) => setUserName(e.target.value);
  const onPasswordChange = (e) => setPassword(e.target.value);
  const onBearerTokenChange = (e) => setBearerToken(e.target.value);

  // Initialize state from authValue
  useEffect(() => {
    if (authValue) {
      const selectedOption = Object.values(AUTH_OPTION).find(
        (opt) => opt.key === authValue.key
      );
      setAuthOption(selectedOption);

      if (authValue.key === AUTH_OPTION.BASIC_AUTH.key && authValue.value) {
        setUserName(authValue.value.userName || "");
        setPassword(authValue.value.pass || "");
      } else if (
        authValue.key === AUTH_OPTION.BEARER_TOKEN.key &&
        authValue.value
      ) {
        setBearerToken(authValue.value || "");
      }
    }
  }, [authValue]);

  // Call onAuthChange whenever authOption or data fields change
  useEffect(() => {
    let authData = {};
    let valid = false;

    if (authOption.key === AUTH_OPTION.NO_AUTH.key) {
      authData = { ...AUTH_OPTION.NO_AUTH, value: "" };
      valid = true; // No Auth is always valid
    } else if (authOption.key === AUTH_OPTION.BASIC_AUTH.key) {
      authData = {
        ...AUTH_OPTION.BASIC_AUTH,
        value: { userName, pass: password },
      };
      valid = userName.trim() !== "" && password.trim() !== ""; // Basic Auth is valid only if both fields are filled
    } else if (authOption.key === AUTH_OPTION.BEARER_TOKEN.key) {
      authData = {
        ...AUTH_OPTION.BEARER_TOKEN,
        value: bearerToken,
      };
      valid = bearerToken.trim() !== ""; // Bearer Token is valid if the token is provided
    }

    // Pass back the auth data and validity
    onAuthChange({ ...authData, valid });
  }, [authOption, userName, password, bearerToken]);

  const renderFieldsForAuthOptions = useCallback(() => {
    if (authOption.key === "no_auth") {
      return (
        <MDBox
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{CARRIER_INS.NO_AUTH}</Typography>
        </MDBox>
      );
    } else if (authOption.key === "basic_auth") {
      return (
        <MDBox
          display="flex"
          width="100%"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <MDInput
            fullWidth
            type="text"
            label="User Name"
            value={userName}
            onChange={onUserNameChange}
          />
          <MDInput
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={onPasswordChange}
          />
        </MDBox>
      );
    } else if (authOption.key === "bearer_token") {
      return (
        <MDBox
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <MDInput
            fullWidth
            type="text"
            label="Bearer Token"
            value={bearerToken}
            onChange={onBearerTokenChange}
          />
        </MDBox>
      );
    }
  }, [authOption, userName, password, bearerToken]);

  return (
    <>
      <MDBox display="flex" width="40%" mt={1}>
        <MDBox display="flex" margin={"8px"} width="100%">
          <MDBox display="flex" flexDirection="column" width="100%">
            <FormControl fullWidth>
              <InputLabel id="auth">Authorization options</InputLabel>
              <SelectStyle
                labelId="auth"
                id="auth-select"
                label={"Authorization options"}
                sx={{
                  height: "40px",
                  margin: "8px 0px 0px 0px",
                  width: "100%",
                }}
                onChange={onChangeAuthOptionChange}
                value={authOption.key}
              >
                <MenuItem
                  key={AUTH_OPTION.NO_AUTH.key}
                  value={AUTH_OPTION.NO_AUTH.key}
                >
                  {AUTH_OPTION.NO_AUTH.name}
                </MenuItem>
                <MenuItem
                  key={AUTH_OPTION.BASIC_AUTH.key}
                  value={AUTH_OPTION.BASIC_AUTH.key}
                >
                  {AUTH_OPTION.BASIC_AUTH.name}
                </MenuItem>
                <MenuItem
                  key={AUTH_OPTION.BEARER_TOKEN.key}
                  value={AUTH_OPTION.BEARER_TOKEN.key}
                >
                  {AUTH_OPTION.BEARER_TOKEN.name}
                </MenuItem>
              </SelectStyle>
            </FormControl>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox display="flex" width="40%" flexDirection="row" mt={2} ml={1}>
        {renderFieldsForAuthOptions()}
      </MDBox>
    </>
  );
};

export default AuthParams;
