import { Typography } from "@mui/material";
import KeyValue from "components/CommonUI/KeyValue";
import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";
import { CARRIER_INS } from "constants/AppConstants";
import { BLIP_GATEWAY } from "constants/Endpoints";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { memo, useState, useCallback, useEffect } from "react";

let itemIdCounter = 0; // Unique ID counter

const InboundConfig = ({ setInbound }) => {
  const [inboundData, setInboundData] = useState({
    hook: `${BLIP_GATEWAY}/sms/inbound`,
    body: [
      { key: "", value: "", id: itemIdCounter++, param: "" },
      { key: "", value: "", id: itemIdCounter++, param: "" },
      { key: "", value: "", id: itemIdCounter++, param: "" },
      { key: "", value: "", id: itemIdCounter++, param: "" },
    ],
  });

  const getAvailableOptions = (currentItem) => {
    const selectedParams = new Set();

    // Collect selected params from all items except the current one
    inboundData.body.forEach((item) => {
      if (item.param && item.param !== "" && item.id !== currentItem.id) {
        selectedParams.add(item.param);
      }
    });

    // Build the available options
    const availableOptions = CARRIER_REQ_PARAMS_OPT.filter(
      (option) =>
        !selectedParams.has(option.key) || option.key === currentItem.param // Ensure current param is included
    );

    return availableOptions;
  };

  const updateKeyValue = useCallback(
    (value) => {
      setInboundData((prev) => {
        const updatedBody = prev.body.map((itm) =>
          itm.id === value.id ? value : itm
        );
        return { ...prev, body: updatedBody };
      });
    },
    [setInboundData]
  );

  useEffect(() => {
    setInbound(inboundData);
  }, [inboundData]);

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="center">
        <Typography variant="h5" m={2}>
          {"Inbound configurations"}
        </Typography>
      </MDBox>

      <MDBox
        margin={"8px 8px 12px 8px"}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <MDBox width="100%!important" display="flex" flexDirection="column">
          <MDInput
            disabled={true}
            fullWidth
            type="text"
            label="Webhook"
            value={inboundData.hook}
          />
          <Typography mt={1} fontSize={"14px"} fontWeight={600}>
            {CARRIER_INS.WEBHOOK_INS}
          </Typography>
        </MDBox>

        <MDBox display="flex" width="100%" flexDirection="column" mt={2}>
          {inboundData.body.map((itm) => (
            <MDBox
              key={`body_${itm.id}`}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <KeyValue
                item={itm}
                onChange={updateKeyValue}
                availableOptions={getAvailableOptions(itm)}
                required={true}
                canEdit={false}
                showDelete={false}
                showValueField={false}
              />
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default memo(InboundConfig);
