import { MenuItem, Select, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import MDBox from "lib/components/MDBox";
import { APP_COLOR } from "constants/Color";

const RequiredParamsDrop = ({ value, onChange, options = [], style = {} }) => {
  const onDropdownChange = (event) => {
    onChange(event.target.value);
  };

  const handleClear = () => {
    onChange(""); // Clear the selected value
  };

  return (
    <MDBox
      width="100%"
      style={{ ...style, display: "flex", alignItems: "center" }}
    >
      <Select
        value={value}
        onChange={onDropdownChange}
        displayEmpty
        sx={{
          width: "148px",
          height: "36px",
          "& .MuiSvgIcon-root": {
            color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
            fontSize: "18px!important",
          },
        }}
        SelectDisplayProps={{
          style: { color: `${APP_COLOR.APP_SECONDARY_COLOR}` },
        }}
        renderValue={(selected) => {
          if (selected === "") {
            return <em>Select Param</em>;
          }
          const selectedOption = options.find((opt) => opt.key === selected);
          return selectedOption ? selectedOption.value : selected;
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((item) => (
          <MenuItem
            key={item.key}
            value={item.key}
            style={{ color: `${APP_COLOR.APP_SECONDARY_COLOR}` }}
          >
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {value && (
        <IconButton size="small" onClick={handleClear}>
          <Clear fontSize="small" />
        </IconButton>
      )}
    </MDBox>
  );
};

export default RequiredParamsDrop;
