import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";
import axios from "axios";

export const saveCarrier = async ({ req, token }) => {
  try {
    const response = await axios.post(`${BASE_URL}/phone/carrier`, req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Could not call the endpoint."
    );
  }
};
export const getAllCarrier = async (token) => {
  const response = await fetch(`${BASE_URL}/phone/carrier`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not call the endpoint.");
  }
  return data;
};

export const getCarrierResponse = async (carrierData) => {
  try {
    const method = carrierData.http_method || "POST";

    let url = carrierData.send_url;
    let headers = {};
    headers["Content-Type"] = carrierData.content_type || "application/json";

    let params = new URLSearchParams();

    const keyValue = carrierData.request_body;
    // Handle Authentication
    if (keyValue && keyValue.auth) {
      const auth = keyValue.auth;

      if (auth.name === "Bearer Token") {
        // Bearer Token Authentication
        headers["Authorization"] = "Bearer " + auth.value;
      } else if (auth.name === "Basic Auth") {
        // Basic Authentication
        const credentials = `${auth.username}:${auth.password}`;
        const base64Credentials = btoa(credentials);
        headers["Authorization"] = "Basic " + base64Credentials;
      } else {
      }
    }

    console.log("Carrier Data", keyValue);

    if (keyValue?.headers && keyValue?.headers.length > 0) {
      keyValue.headers.forEach((item) => {
        if (item.key && item.value) {
          headers[item.key] = item.value;
        }
      });
    }

    if (keyValue?.params) {
      keyValue.params.forEach((item) => {
        if (item.key && item.value) {
          params.append(item.key, item.value);
        }
      });
    }

    // Append query parameters to URL
    const queryString = params.toString();
    if (queryString) {
      url += (url.includes("?") ? "&" : "?") + queryString;
    }

    // Process Body Data
    let bodyData = {};
    if (keyValue?.body) {
      keyValue.body.forEach((item) => {
        if (item.param === "media") {
          console.log("media irtem", item, bodyData);
          if (bodyData[item.key]) {
            bodyData[item.key].push(item.value);
          } else {
            bodyData[item.key] = [item.value];
          }
          return;
        }
        if (item.key && item.value) {
          bodyData[item.key] = item.value;
        }
      });
    }

    console.log("Body", bodyData);

    // Set up axios options
    const options = {
      method: method,
      url: url,
      headers: headers,
      data: bodyData, // Axios automatically stringifies the body data when Content-Type is application/json
    };

    const response = await axios(options);
    // console.log("Response", response);


    // Dummy Response to check and debug.
    // const response = {
    //   data: {
    //     message_id: 113065,
    //     text: "1",
    //     media: [
    //       {
    //         id: 4670,
    //         uuid: null,
    //         src: "https://example.com/x5rff.png",
    //       },
    //     ],
    //     inbound: false,
    //     scheduled_at: null,
    //     status: "sending",
    //     created_at: "2024-10-09T16:04:07.420765126",
    //     updated_at: null,
    //     sent_by_phone: "8483122221",
    //     sent_to: "8482165040",
    //     is_broadcast: false,
    //     broadcast_name: null,
    //   },
    //   status: 200,
    //   statusText: "",
    //   headers: {
    //     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
    //     "content-type": "application/json",
    //     expires: "0",
    //     pragma: "no-cache",
    //   },
    //   config: {
    //     transitional: {
    //       silentJSONParsing: true,
    //       forcedJSONParsing: true,
    //       clarifyTimeoutError: false,
    //     },
    //     adapter: ["xhr", "http", "fetch"],
    //     transformRequest: [null],
    //     transformResponse: [null],
    //     timeout: 0,
    //     xsrfCookieName: "XSRF-TOKEN",
    //     xsrfHeaderName: "X-XSRF-TOKEN",
    //     maxContentLength: -1,
    //     maxBodyLength: -1,
    //     env: {},
    //     headers: {
    //       Accept: "application/json, text/plain, */*",
    //       "Content-Type": "application/json",
    //       "Bliq-API-Key":
    //         " 6R8V7GqNvJwT6Ary8wcEzNUB+UAEY61GNh7PmdNJA7n/+r3ApMLsEX7WthcdZlqV",
    //     },
    //     method: "post",
    //     url: "https://dev.bliqmsg.com/api/v1/sms/send/message",
    //     data: '{"text":"1","phone":"8483122221","send_to":"8482165040","media":["https://example.com/x5rff.png"],"inbound":"false"}',
    //   },
    //   request: {},
    // };

    return response;
  } catch (error) {
    console.error("Error Fetching Carrier Response", error);
    return error;
  }
};
