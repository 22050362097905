import { Add } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import KeyValue from "components/CommonUI/KeyValue";
import MDBox from "lib/components/MDBox";
import { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import AuthParams from "./AuthParams";
import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const TAB = {
  auth: "auth",
  headers: "headers",
  params: "params",
  body: "body",
};

let itemIdCounter = 0; // Unique ID counter

const OutboundRequestConfig = ({ onKeyValueChange }) => {
  const [currentTab, setCurrentTab] = useState(TAB.headers);
  const [isAuthValid, setIsAuthValid] = useState(true);

  const [keyValue, setKeyValue] = useState({
    headers: [
      { type: TAB.headers, key: "", value: "", id: itemIdCounter++, param: "" },
    ],
    auth: { name: "No Auth", key: "no_auth", value: "" },
    params: [
      { type: TAB.params, key: "", value: "", id: itemIdCounter++, param: "" },
    ],
    body: [
      { type: TAB.body, key: "", value: "", id: itemIdCounter++, param: "" },
    ],
  });

  useEffect(() => {
    onKeyValueChange(keyValue);
  }, [keyValue]);

  const handleAuthChange = (newAuthValue) => {
    setIsAuthValid(newAuthValue.valid); // Update auth validity
    setKeyValue((prev) => ({
      ...prev,
      auth: { ...newAuthValue },
    }));
  };

  // Helper function to check if all key-value pairs in a given section are filled
  const areAllFieldsFilled = (section) => {
    const result = keyValue[section].every(
      (item) => item.key.trim() !== "" && item.value.trim() !== ""
    );
    return result;
  };

  // Validate all sections for completeness
  const validateAllSections = (section) => {
    if (section === "auth") return isAuthValid;

    if (keyValue[section].length === 1) return true;

    return keyValue[section].every(
      (item) => item.key.trim() !== "" && item.value.trim() !== ""
    );
  };

  // Tab change handler that prevents switching tabs if validation fails
  const handleChange = (event, newValue) => {
    if (validateAllSections(currentTab)) {
      setCurrentTab(newValue);
    } else {
      toast.warn("Please fill out all the fields before switching tabs.");
    }
  };

  const getAvailableOptions = (currentItem) => {
    const selectedParams = new Set();

    // Collect selected params from all items except the current one
    ["headers", "params", "body"].forEach((section) => {
      if (keyValue[section] && Array.isArray(keyValue[section])) {
        keyValue[section].forEach((item) => {
          if (item.param && item.param !== "" && item.id !== currentItem.id) {
            selectedParams.add(item.param);
          }
        });
      }
    });

    // Build the available options
    const availableOptions = CARRIER_REQ_PARAMS_OPT.filter(
      (option) =>
        !selectedParams.has(option.key) || option.key === currentItem.param // Ensure current param is included
    );

    return availableOptions;
  };

  const updateKeyValue = useCallback(
    (value) => {
      setKeyValue((prev) => {
        const updatedSection = prev[value.type].map((itm) =>
          itm.id === value.id ? value : itm
        );
        return {
          ...prev,
          [value.type]: updatedSection,
        };
      });
    },
    [setKeyValue]
  );

  const deleteHeader = useCallback(
    (itm) => {
      if (keyValue.headers.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          headers: [
            {
              type: TAB.headers,
              key: "",
              value: "",
              id: itemIdCounter++,
              param: "",
            },
          ],
        }));
        return;
      }
      const newHeaders = keyValue.headers.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        headers: newHeaders,
      }));
    },
    [keyValue.headers]
  );

  const deleteParams = useCallback(
    (itm) => {
      if (keyValue.params.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          params: [
            {
              type: TAB.params,
              key: "",
              value: "",
              id: itemIdCounter++,
              param: "",
            },
          ],
        }));
        return;
      }
      const newParams = keyValue.params.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        params: newParams,
      }));
    },
    [keyValue.params]
  );

  const deleteBody = useCallback(
    (itm) => {
      if (keyValue.body.length === 1) {
        setKeyValue((prev) => ({
          ...prev,
          body: [
            {
              type: TAB.body,
              key: "",
              value: "",
              id: itemIdCounter++,
              param: "",
            },
          ],
        }));
        return;
      }
      const newBody = keyValue.body.filter((item) => itm.id !== item.id);
      setKeyValue((prev) => ({
        ...prev,
        body: newBody,
      }));
    },
    [keyValue.body]
  );

  const addMoreHeaders = useCallback(() => {
    if (areAllFieldsFilled("headers")) {
      const newHeader = {
        type: TAB.headers,
        key: "",
        value: "",
        id: itemIdCounter++,
        param: "",
      };
      setKeyValue((prev) => ({
        ...prev,
        headers: [...prev.headers, newHeader],
      }));
    } else {
      toast.warn("Please fill out all headers before adding more.");
    }
  }, [setKeyValue, keyValue.headers]);

  const addMoreParams = useCallback(() => {
    if (areAllFieldsFilled("params")) {
      const newParam = {
        type: TAB.params,
        key: "",
        value: "",
        id: itemIdCounter++,
        param: "",
      };
      setKeyValue((prev) => ({
        ...prev,
        params: [...prev.params, newParam],
      }));
    } else {
      toast.warn("Please fill out all params before adding more.");
    }
  }, [setKeyValue, keyValue.params]);

  const addMoreBody = useCallback(() => {
    if (areAllFieldsFilled("body")) {
      const newBody = {
        type: TAB.body,
        key: "",
        value: "",
        id: itemIdCounter++,
        param: "",
      };
      setKeyValue((prev) => ({
        ...prev,
        body: [...prev.body, newBody],
      }));
    } else {
      toast.warn("Please fill out all body fields before adding more.");
    }
  }, [setKeyValue, keyValue.body]);

  return (
    <>
      <MDBox sx={{ width: "100%" }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          textColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value={TAB.headers} label="Headers" />
          <Tab value={TAB.auth} label="Auth" />
          <Tab value={TAB.params} label="Params" />
          <Tab value={TAB.body} label="Body" />
        </Tabs>

        {currentTab === TAB.auth && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <AuthParams
              onAuthChange={handleAuthChange}
              authValue={keyValue.auth}
            />
          </MDBox>
        )}

        {currentTab === TAB.headers && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreHeaders} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.headers.map((itm) => (
              <MDBox
                key={`header_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteHeader(itm)}
                  availableOptions={getAvailableOptions(itm)}
                />
              </MDBox>
            ))}
          </MDBox>
        )}

        {currentTab === TAB.params && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreParams} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.params.map((itm) => (
              <MDBox
                key={`param_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteParams(itm)}
                  availableOptions={getAvailableOptions(itm)}
                />
              </MDBox>
            ))}
          </MDBox>
        )}

        {currentTab === TAB.body && (
          <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox
              display="flex"
              sx={{ height: "28px" }}
              width="100%"
              justifyContent="flex-end"
            >
              <Button onClick={addMoreBody} startIcon={<Add />}>
                {"Add more"}
              </Button>
            </MDBox>
            {keyValue.body.map((itm) => (
              <MDBox
                key={`body_${itm.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <KeyValue
                  item={itm}
                  onChange={updateKeyValue}
                  onDelete={() => deleteBody(itm)}
                  availableOptions={getAvailableOptions(itm)}
                />
              </MDBox>
            ))}
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default OutboundRequestConfig;
