import { Delete } from "@mui/icons-material";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useState, useEffect } from "react";
import RequiredParamsDrop from "components/Carrier/RequiredParamsDrop";
import useDebouncedWatch from "hooks/use-debounce-watch";

const KeyValue = ({
  item,
  onDelete,
  onChange,
  showDelete = true,
  canEdit = true,
  required = false,
  availableOptions = [],
  showValueField = true, // Prop to control whether to show the value field
}) => {
  const [value, setValue] = useState(item);

  useDebouncedWatch(() => {
    onChange(value);
  }, [value]);

  // Ensure value updates when item changes
  useEffect(() => {
    setValue(item);
  }, [item]);

  return (
    <MDBox
      display="flex"
      width="100%"
      flexDirection="row"
      mb={2}
      alignItems="center"
    >
      {/* Key Field */}
      <MDBox width="30%!important" padding="4px">
        <MDInput
          fullWidth
          required={required}
          type="text"
          label={"Key"}
          value={value.key}
          onChange={(e) => {
            setValue((prev) => ({
              ...prev,
              key: e.target.value,
            }));
          }}
        />
      </MDBox>

      {/* Value Field */}
      {showValueField && (
        <MDBox width="30%!important" padding="4px">
          <MDInput
            fullWidth
            type="text"
            label={"Value"}
            value={value.value}
            onChange={
              canEdit
                ? (e) => {
                    setValue((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }));
                  }
                : () => {}
            }
          />
        </MDBox>
      )}

      {/* Param (Dropdown) */}
      <MDBox width="25%!important" padding="4px">
        <RequiredParamsDrop
          value={value.param || ""}
          onChange={(selectedParam) => {
            setValue((prev) => ({
              ...prev,
              param: selectedParam, // 'selectedParam' is the 'key' or empty string
            }));
          }}
          options={availableOptions}
          style={{ height: "28px" }}
        />
      </MDBox>

      {/* Delete Icon */}
      {showDelete && (
        <MDBox width="10%!important" display="flex" justifyContent="center">
          <Delete onClick={onDelete} sx={{ cursor: "pointer" }} />
        </MDBox>
      )}
    </MDBox>
  );
};

export default KeyValue;
